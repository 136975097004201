@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Visuelt Pro";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("VisueltPro-Regular"),
    url("./fonts/VisueltPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "Visuelt Pro Bold";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("VisueltPro-Bold"),
    url("./fonts/VisueltPro-Bold.woff") format("woff");
}

@font-face {
  font-family: "Visuelt Pro Black";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: local("VisueltPro-Black"),
    url("./fonts/VisueltPro-Black.woff") format("woff");
}

@font-face {
  font-family: "Visuelt Pro Medium";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local("VisueltPro-Medium"),
    url("./fonts/VisueltPro-Medium.woff") format("woff");
}

@font-face {
  font-family: "Visuelt Pro Thin";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: local("VisueltPro-Thin"),
    url("./fonts/VisueltPro-Thin.ttf") format("ttf");
}

.blue-red-gradient {
  background-image: linear-gradient(to right, var(--gradient-color-stops));
  --gradient-from-color: #2f17a3;
  --gradient-color-stops: var(--gradient-from-color),
    var(--gradient-to-color, rgba(47, 23, 163, 0));
  --gradient-to-color: #ea002c;
}

body {
  font-family: "Visuelt Pro Black", sans-serif;
}
